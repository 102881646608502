/* eslint-disable import/prefer-default-export */

/**
 * Returns the url of the image resource.
 *
 * @param {string} src The source of the image
 */
export function getImageResource(src) {
  if (!src) {
    return null;
  }
  if (src.toString().startsWith("http")) {
    return src;
  }

  return `${process.env.VUE_APP_STORAGE_URL}/${src}`;
}
